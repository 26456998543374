/* Center the overall container */
.reporting-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    text-align: center;
  }
  
  .reporting-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .reporting-description {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  /* Metrics wrapper to arrange counters */
  .metrics-wrapper {
    display: flex;
    justify-content: center;
    gap: 2rem; /* Space between counters */
  }
  
  /* Circular counter styling */
  .circular-counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .counter-value {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
  }
  
  .counter-title {
    font-size: 1rem;
    color: #777;
    margin-top: 0.5rem;
  }
  
  /* Loading and error states */
  .loading,
  .error {
    font-size: 1.2rem;
    color: #888;
  }

  /* Updated metrics-wrapper for three counters */
.metrics-wrapper {
    display: flex;
    justify-content: center;
    gap: 2rem; /* Adjust spacing for three counters */
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
  }
  
  /* Ensure circular counters remain the same */
  .circular-counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .counter-value {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
  }
  
  .counter-title {
    font-size: 1rem;
    color: #777;
    margin-top: 0.5rem;
  }
  
  .alerts-wrapper {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .alert {
    padding: 1rem;
    border-radius: 8px;
    font-size: 1rem;
  }
  
  .alert-success {
    background-color: #d1e7dd;
    color: #0f5132;
    border: 1px solid #badbcc;
  }
  
  .alert-info {
    background-color: #cff4fc;
    color: #055160;
    border: 1px solid #b6effb;
  }
  