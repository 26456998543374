@keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .dots::after {
    content: "...";
    display: inline-block;
    animation: blink 1.5s infinite;
  }
  
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }
  
  .loading-text {
    font-size: 1.2rem;
    font-weight: bold;
    color: #007bff;
  }
  